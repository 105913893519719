.DateRange2ModeSelector {
  display: flex;
  align-items: center;
}

.label {
  display: inline;
}

.items {
  display: flex;
  align-items: center;
  margin-left: 4px;
}

.item {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  color: var(--color2);
  cursor: pointer;
}

.item:before {
  content: '·';
  margin-left: 2px;
}

.item:first-child:before {
  content: none;
}

.item:hover {
  color: #f88001;
}
