.SubMenuItem {
    font-size: 13px;
    line-height: 38px;
    cursor: pointer;
    text-decoration: none;
    color: #137756;
    border-bottom: 3px solid white;
  flex-shrink: 0;
}

.SubMenuItem:hover {
    border-bottom: 3px solid #137756;
}

.SubMenuItemActive {
    border-bottom: 3px solid #137756;
}
