.control {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.controlBtn {
  width: 13px;
  height: 13px;
  cursor: pointer;
  background-size: contain;
  background-image: url('/src/shared/images/date-range-arrow.svg');
}

.controlBtnBack {
}

.controlBtnForward {
  transform: rotate(180deg);
}

.controlText {
  color: var(--color1);
  font-size: 12px;
  cursor: pointer;
}
