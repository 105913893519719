body {
    --color1: rgb(2, 45, 33);
    --color2: rgb(19, 119, 86);
    --color3: rgb(241, 247, 246);
    --color-red: #df2059;
    --color-green: #3ac267;
    --color-orange: #cc420c;

    --color-ozon: #125EFF;
    --color-ozon-50: rgba(18, 94, 255, 0.5);
    --color-ozon-25: rgba(18, 94, 255, 0.25);
    --color-yandex: #FACD00;
    --color-yandex-50: rgba(250, 205, 0, 0.5);
    --color-yandex-25: rgba(250, 205, 0, 0.25);
    --color-wb: #F01A8A;

  --neutral-600: #203148;
  --neutral-500: #546378;
  --neutral-400: #707e90;
  --neutral-300: rgba(97,131,162,0.43);
  --neutral-200: rgba(97,131,162,0.26);
  --neutral-100: rgba(97,131,162,0.16);
  --neutral-50: rgba(97,131,162,0.1);

    margin: 0;
    font: 12px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

html, body {
  height: 100%;
}

#root {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
}

.appContent {
  padding: 20px 20px 10px;
  height: 100%;
  position: relative;
}

.negative {
  color: var(--color-red);
}

.positive {
  color: var(--color-green);
}
