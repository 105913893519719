.header {
}

.mainRow {
    background-color: var(--color2);
    height: 58px;
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr auto;
    gap: 20px;
}

.subRow {

}

.logo {
    height: 40px;
    width: 40px;
}

.menu {

}

.user {
    margin-right: 20px;
}