.user {
    display: flex;
    flex-direction: row;
    height: 58px;
}

.user:hover {
    background-color: #116a4d;
}

.column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 14px 0 5px;
}

.name {
    font-size: 12px;
    color: white;
    text-align: right;
}

.login {
    font-size: 11px;
    color: white;
    text-align: right;
}

.avatar {
    width: 36px;
    height: 36px;
    margin: auto 5px auto 0;
    background: url('../../../../shared/images/header/avatar.svg') no-repeat;
}
