.MenuItem {
    height: 58px;
    margin-right: 8px;
    padding: 0 8px;
    color: #9cf0d4;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
  min-width: 50px;
}

.MenuItem:hover {
    background-color: #116a4d;
    color: white;
}

.itemActive {
    background-color: #0a5b40;
    color: white;
}

.itemActive:hover {
    background-color: #0a5b40;
}

.itemIcon {
    height: 38px;
    color: white;
    font: 11px "“Helvetica Neue”", Helvetica, Arial, "“Lucida Grande”", sans-serif;
    background-position: center;
    background-size: 24px 24px;
    background-repeat: no-repeat;
}

.itemTypeProducts {
}

.itemTypeMarketplaces {
    background-image: url('/src/shared/images/header/menu/marketplaces.svg');
}

.itemTypeReports {
    background-image: url('/src/shared/images/header/menu/reports.svg');
}

.itemTypeFinance {
    background-image: url('/src/shared/images/header/menu/money.svg');
}

.itemTypeSettings {
    background-image: url('/src/shared/images/header/menu/settings.svg');
}

.itemTypeLoyalty {
    background-image: url('/src/shared/images/header/menu/loyalty.svg');
}

.ozon {
  background-image: url('/src/shared/images/header/menu/ozon.svg');
}

.wb {
  background-image: url('/src/shared/images/header/menu/wb.svg');
}

.yandex {
  background-image: url('/src/shared/images/header/menu/yandex.svg');
}

.itemText {
    height: 20px;
    margin: 0 auto;
    font: 11px "“Helvetica Neue”", Helvetica, Arial, "“Lucida Grande”", sans-serif;
}
